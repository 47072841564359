import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, navigate } from "gatsby"
import get from "lodash/get"
import isEmail from "validator/lib/isEmail"
import isEmpty from "validator/es/lib/isEmpty"
import Cookie from "js-cookie"
import { useToast } from "@chakra-ui/react"
import { postForm } from "../api"
import Layout from "../components/layout"
import ContactUsForm from "../components/contact-us/form"
import MessageConfirmation from "../components/contact-us/message-confirmation"
import Hero from "../components/hero"

const styles = {
  hero: {
    root: {
      justifyContent: "center",
      px: "0px",
    },
    "left-box": {
      width: { base: "auto", md: "auto", lg: "300px" },
    },
    image: {
      height: { base: "auto", md: "auto", lg: "300px" },
      width: { base: "100%", md: "100%", lg: "500px" },
      ml: { base: "0px", md: "0px", lg: "26px" },
    },
    heading: {
      textAlign: { base: "center", md: "center", lg: "left" },
    },
  },
}

const createValidator = field => value => {
  if (field.required && isEmpty(value)) {
    return `${field.label} is required`
  }
  if (field.name === "email" && !isEmail(value)) {
    return "Please enter a valid email address"
  }
  return ""
}

const createField = fg => {
  const fields = fg.fields.map(field => ({
    ...field,
    id: field.name,
    validate: createValidator(field),
    value: "",
    hint: "",
  }))
  return fields.length > 1 ? fields : fields[0]
}

const ContactUsIndex = props => {
  const submitText = get(props, "data.hubspotForm.submitText")
  const fieldGroups = get(props, "data.hubspotForm.formFieldGroups")
  const portalId = get(props, "data.hubspotForm.portalId")
  const inlineMessage = get(props, "data.hubspotForm.inlineMessage")
  const hutk = Cookie.get("hubspotutk")
  const pageUri = get(props, "location.href")
  const toast = useToast()
  const [loading, setLoading] = React.useState(false)
  const fields = React.useMemo(
    () => fieldGroups.map(fg => createField(fg)),
    [fieldGroups]
  )

  const handleSubmit = React.useCallback(
    data => {
      setLoading(true)
      const context = { hutk, pageUri, pageName: "Contact Us" }
      postForm({
        portalId,
        formId: "c169e0ff-1ac8-4ec7-9a44-4b1b20739232",
        data,
        context,
      }).then(() => {
        setLoading(false)
        toast({
          duration: null,
          render: () => (
            <MessageConfirmation
              message={inlineMessage}
              onClose={() => {
                toast.closeAll()
                navigate("/")
              }}
            />
          ),
        })
      })
    },
    [portalId, pageUri, inlineMessage, toast, hutk]
  )
  return (
    <Layout location={props.location} title={"Contact Us"}>
      <Hero
        title="Contact Us at Avant-garde Health"
        content="We want to hear from you!"
        Image={() => (
          <StaticImage
            src="../images/contact/contact-us-image.png"
            alt="Contact Us at Avant-garde Health"
            placeholder="blurred"
          />
        )}
        styles={styles.hero}
      />
      <ContactUsForm
        fields={fields}
        submitText={submitText}
        loading={loading}
        onSubmit={handleSubmit}
      />
    </Layout>
  )
}

export default ContactUsIndex

export const pageQuery = graphql`
  query HubpotForm {
    hubspotForm(id: { eq: "c169e0ff-1ac8-4ec7-9a44-4b1b20739232" }) {
      portalId
      name
      submitText
      inlineMessage
      formFieldGroups {
        fields {
          label
          name
          required
          fieldType
        }
      }
    }
  }
`
