import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
import { Box, Text, Link } from "@chakra-ui/react"
import Form from "../splash/form"

const styles = {
  button: {
    bg: "#129459",
    color: "white",
    display: "block",
    width: "100%",
  },
  form: {
    width: { base: "100%", md: "100%", lg: "35em" },
    margin: "0 auto",
    px: { base: "24px", md: "24px", lg: "0px" },
    mb: "80px",
  },
  formGroup: {
    padding: "0.75em 0 0.5em",
    margin: "1em 0",
  },
  smallFormGroup: {
    borderRadius: "0.25em",
    padding: "0.75em 0 0.5em",
    width: "45%",
    margin: "0",
  },
  formLabel: {
    display: "inline-block",
    bg: "white",
    color: "grey",
    font: "Roboto",
    padding: "0.25em 0.5em",
    fontSize: "14px",
    margin: "0",
    marginLeft: "10px",
    position: "absolute",
    lineHeight: "1.0",
    top: ".25em",
    zIndex: "1000",
  },
  input: {
    padding: "0.5em",
    height: "56px",
    borderRadius: "4px",
  },
  select: {
    height: "56px",
    border: "1px solid",
    borderColor: "inherit",
    borderRadius: "4px",
  },
  "text-box": {
    borderBottom: "1px solid lightgrey",
    mt: "5em",
    mb: "2.5em",
    paddingBottom: "2.5em",
  },
  link: {
    color: "#3399FF",
    _hover: {
      color: "#3399FF",
    },
  },
}

const ContactUsForm = ({ fields, submitText, onSubmit, loading }) => {
  return (
    <Box sx={styles.form}>
      <Box sx={styles["text-box"]}>
        <Text fontSize="sm" textAlign="center">
          You can contact us at info@avantgardehealth.com or complete the form
          below.
        </Text>

        <Text fontSize="sm" textAlign="center" mt="24px">
          Privacy is important to us. Read our policy{" "}
          <Link as={GatsbyLink} sx={styles.link} to="/privacy-policy">
            here
          </Link>
          .
        </Text>
      </Box>
      <Form
        fields={fields}
        submitText={submitText}
        onSubmit={onSubmit}
        loading={loading}
      />
    </Box>
  )
}

ContactUsForm.propTypes = {
  fields: PropTypes.array,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
}

export default ContactUsForm
